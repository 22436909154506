import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog31/image1.jpg"







export default function EverythingYouWantIsOnTheOtherSideOfHard(){
    return(
        <BlogPostOld
            src={image}
            title={"Everything You Want is on the Other Side of Hard"}
            summary={                "We are sold “easy” way too often as a society. I see this a lot in the health and fitness industry. It is a tactic where something is over promised and oftentimes under delivers.We are sold that we can precisely identify a “problem” and in turn easily “fix” it. This is because the truth to bouncing back from an injury or maintaining physical capabilities isn’t sexy. "}
            date={"Feb. 28, 2023"}
            author={"Dr. Donald Mull, DC"}
            InStyle={"max-h-full"}

        >
            <div>
                <p>We are sold &ldquo;easy&rdquo; way too often as a society. I see this a lot in the health and fitness industry. It is a tactic where something is over promised and oftentimes under delivers.</p>

                <p>We are sold that we can precisely identify a &ldquo;problem&rdquo; and in turn easily &ldquo;fix&rdquo; it. This is because the truth to bouncing back from an injury or maintaining physical capabilities isn&rsquo;t sexy.</p>

                <p>Telling people that being consistent with hard work and mastering basics doesn&rsquo;t have the same ring to it as &ldquo;I started taking this supplement and all of my problems went away&rdquo; or &ldquo;I started doing this exercise everyday and now my pain is gone&rdquo;. Doesn&rsquo;t it sound way more appealing to just be able to do this one thing and have all your troubles melt away?</p>

                <p>I have spent thousands of hours trying to validate this way of thinking because I am just like you, I want to find the path of least resistance to become and stay physically capable. For me that looks like reducing risk of injury, being able to bounce back from injury and being able to play any type of competitive physical activity at the drop of a hat (I may be the most competitive person I have ever met).</p>

                <p>That being said, there is no &ldquo;easy&rdquo; way out. To get the most out of your body, you need to provide a challenging stimulus. This includes when recovering from or in the presence of an injury. This process doesn&rsquo;t need to be overcomplicated though. I am going to break down the steps I take for myself personally whenever I have an injury or am working to attain a fitness goal (it is the same process I go through when I help guide others as well).</p>

                <p>First thing first, start with the end in mind. You need to start with a goal. Losing weight, getting strong, being more mobile are all great starting points but can you get more detailed? Your goal should mean something to you.</p>

                <p>When you are stronger, less heavy, more fit, what do you picture yourself accomplishing or feeling? Do you want to feel less out of breath when playing with your kid so you don&rsquo;t have to always be the one to call it short? Do you want to feel confident anytime you step on the court with your buddies so you can back up the smack talk in the office?</p>

                <p>Once you have your goal, you have a true North Star. This should mean more to you than anyone else because it isn&rsquo;t a generic statement - it&rsquo;s YOUR brand.</p>

                <p>Next, you must break down what must be done to achieve this goal and create a game plan. Action without a plan can create frustration and resentment. Some components of the plan that are absolutely necessary are a better understanding of where you are at currently in regards to physical abilities, what physical capabilities are needed to achieve your goals, what equipment is needed vs what is available, the time you are willing to dedicate to achieve your goals and how long you are willing to work to get to your goals.</p>

                <p>With this information, you can put together a rough draft though having help of a practitioner in this step can be extremely helpful.</p>

                <p>Lastly, be willing to make adjustments. Your plan should be fluid. Life is not easy and can throw curveballs that challenge us. Be willing to adjust as obstacles come up - this is actually how you build resiliency and attain skills that help long term sustainability. This is why at Kinetic Impact we make sure to provide you with support as long as you need it; we are in your corner.</p>

                <p>This process is far from easy and this is why becoming healthy and resilient is so damn rewarding. Things that come from hard work are the things that hold the highest value to us. This is why we are not afraid to tell you the truth. Recovering from an injury is hard. Breaking through that training plateau is hard. Getting back into working out or being physically active is hard. This is why we created Kinetic Impact - to help people navigate through hard to live a life with more confidence and less physical limitations. </p>

                </div>

        </BlogPostOld>

    )
}